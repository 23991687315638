import { useRequest } from '@/composables/useRequest';
import { ApiMethod } from '@/enums/api.enum';
import type { CollectionListItem } from '@/types/collection/collection.type';
import type {
  CollectionRequest,
  CollectionSearchRequest,
  SimpleCollectionRequest
} from '@/types/collection/collection-request.type';
import type {
  CollectionChildrenResponse,
  CollectionDetailResponse,
  CollectionRepresentativeResponse,
  SimpleSuccessResponse
  // CollectionListResponse
} from '@/types/collection/collection-response.type';
import type { SuccessResponse } from '@/types/common/common.type';
import type { Pagination } from '@/types/common/pagination.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=472581978
export const fetchCollectionChildrenApi = async (params: {
  groupId: string;
  q?: string;
}): Promise<CollectionChildrenResponse | undefined> => {
  const { data } = await useRequest<CollectionChildrenResponse>('studio/v1/collections/children', {
    headers: { ...generateHeader('X-Lang') },
    method: ApiMethod.Get,
    params: camelToSnake(params)
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=472608093
export const fetchCollectionListApi = async (
  groupId: string,
  params?: CollectionSearchRequest
): Promise<Pagination<CollectionListItem[]> | undefined> => {
  const { data } = await useRequest<Pagination<CollectionListItem[]>>(
    `studio/v1/collections/search/groups/${groupId}`,
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get,
      params: camelToSnake({ ...params })
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=472589962
export const fetchCollectionDetailsApi = async (
  productNo: number
): Promise<CollectionDetailResponse | undefined> => {
  const { data } = await useRequest<CollectionDetailResponse>(
    `studio/v1/collections/${productNo}`,
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=470315764 --> DEPRECATED
export const createCollectionApi = async (
  collectionRequest: CollectionRequest
): Promise<SuccessResponse | undefined> => {
  const { data } = await useRequest<SuccessResponse>('studio/v1/collections', {
    method: ApiMethod.Post,
    data: collectionRequest
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=495912526
export const createSimpleCollectionApi = async (collectionRequest: SimpleCollectionRequest) => {
  const { data } = await useRequest<SimpleSuccessResponse>('studio/v1/collections', {
    method: ApiMethod.Post,
    data: camelToSnake(collectionRequest)
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=476585914
export const modifyCollectionApi = async (
  productNo: number,
  collectionRequest: CollectionRequest
): Promise<SuccessResponse | undefined> => {
  const { data } = await useRequest<SuccessResponse>(`studio/v1/collections/${productNo}`, {
    method: ApiMethod.Put,
    data: collectionRequest,
    cancelAfter: 0,
    showCommonError: true
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=495912154
export const fetchCollectionRepresentativeProductApi = async (
  productNo: string
): Promise<CollectionRepresentativeResponse | undefined> => {
  const { data } = await useRequest<CollectionRepresentativeResponse>(
    `studio/v1/collections/representative-product-info/${productNo}`,
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=476585914
export const createCollectionReleaseApi = async (
  productNo: number,
  collectionRequest: CollectionRequest
): Promise<SuccessResponse | undefined> => {
  const { data } = await useRequest<SuccessResponse>(`studio/v1/collections/${productNo}`, {
    method: ApiMethod.Post,
    data: collectionRequest,
    cancelAfter: 0
  });

  return data;
};
